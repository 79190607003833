import styled from 'styled-components';
import { Wrapper } from 'fatcat-ui-library/components/Atoms/Wrapper';

export const SearchWrapper = styled(Wrapper)`
	.single-value,
	.placeholder {
		${props => props.theme.media.largeTablet} {
			font-size: ${props => props.theme.fontSize.s16};
		}
	}
	.indicators-container {
		div:first-child {
			border-radius: 50%;
			padding: 0;
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 4px;
		}
		div:first-child:hover {
			color: ${props => props.theme.color.textColor};
		}
	}
`;
