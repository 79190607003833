export const categories = [
	{
		name: 'Sinus & Allergy Respiratory',
		url: 'allergy',
		healthConcern: 'Sinus & Allergy Respiratory',
	},
	{
		name: 'General Health & Wellness',
		url: 'general-health-and-wellness',
		healthConcern: 'General Health & Wellness',
	},
	{
		name: 'Immune Support',
		url: 'immune-support',
		healthConcern: 'Immune Support',
	},
	{
		name: 'Inflammation Management',
		url: 'inflammation-management',
		healthConcern: 'Inflammation Management',
	},
	{
		name: 'Reflux, GI, Digestion',
		url: 'reflux-gi-digestion',
		healthConcern: 'Reflux, GI, Digestion',
	},
	{
		name: 'Sleep',
		url: 'sleep',
		healthConcern: 'Sleep',
	},
];
