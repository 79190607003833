export const preventSameQueryLink = (name: string, type: string) => (e: any) => {
	const search = typeof window !== 'undefined' ? window.location.search : '';
	const urlSearchParams = new URLSearchParams(search);
	const parsedName = name.trim().toLowerCase().split(/[\/,]+/);
	const queryParam = (urlSearchParams.get(type) || '').toLowerCase();
	const params = queryParam ? queryParam.split(' ') : [];
	// check if all words are in query params
	const newParamsWords = parsedName.filter(word => !params.some(param => decodeURIComponent(param) === word.trim()));
	if (!newParamsWords.length) {
		e.preventDefault();
	}
};
