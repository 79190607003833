import React, { useState } from 'react';
import Select, {
	components,
	SingleValue,
	GroupBase,
	SelectComponentsConfig,
} from 'react-select';

import { useTheme } from 'styled-components';

// Atoms
import { SearchWrapper } from './SortDropdown.atoms';
import { GeneralProps } from 'fatcat-ui-library/theme/props';

type TOptionLabel = {
	value: string
	label: string
};
type TSortDropdown = {
	selected: (e: string) => void;
}

const searchOptions: (TOptionLabel | GroupBase<TOptionLabel>)[] = [
	{
		label: 'Alphabetically',
		options: [
			{
				value: 'ASC',
				label: 'A-Z',
			},
			{
				value: 'DESC',
				label: 'Z-A',
			},
		],

	},
	{
		label: 'Price',
		options: [
			{
				value: 'price-ASC',
				label: 'Lowest to Highest',
			},
			{
				value: 'price-DESC',
				label: 'Highest to Lowest',
			},
		],
	},
];

const component: SelectComponentsConfig<any, any, any> = {
	SingleValue: ({ className, ...props }) => (
		<components.SingleValue {...props} className="single-value" />
	),
	IndicatorsContainer: ({ className, ...props }) => (
		<components.IndicatorsContainer {...props} className="indicators-container" />
	),
	Placeholder: ({ className, ...props }) => (
		<components.Placeholder {...props} className="placeholder" />
	),
};

const SortDropdown: React.FC<TSortDropdown & GeneralProps> = (props) => {
	const {
		selected,
		...restProps
	} = props;

	const theme = useTheme();
	const [value, setValue] = useState<SingleValue<TOptionLabel>>(null);

	const handleChange = (newValues: SingleValue<TOptionLabel>) => {
		setValue(newValues);
		selected(newValues?.value || '');
	};

	return (
		<SearchWrapper {...restProps}>
			<Select
				isClearable
				isSearchable={false}
				value={value}
				placeholder="Sort by"
				options={searchOptions}
				onChange={handleChange}
				components={component}
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderRadius: '32px',
						borderColor: state.isFocused ? theme.color.primary : theme.color.grey200,
						boxShadow: '0px 0px 12px 0px rgba(79,72,72, 0.1)',
						':hover': {
							borderColor: theme.color.primary,
						},
					}),
					valueContainer: (baseStyles, state) => ({
						...baseStyles,
						minHeight: '32px',
						padding: '4px 10px',
					}),
					placeholder: (baseStyles, state) => ({
						...baseStyles,
						fontSize: theme.fontSize.s16,
						paddingLeft: '8px',
					}),
					singleValue: (baseStyles, state) => ({
						...baseStyles,
						padding: '4px',
						fontSize: theme.fontSize.s16,
					}),
					indicatorsContainer: (baseStyles, state) => ({
						...baseStyles,
						borderRadius: '50%',
						padding: '4px 12px 4px 8px',
						cursor: 'pointer',
					}),
					menuList: (baseStyles, state) => ({
						...baseStyles,
						textAlign: 'left',
					}),
					clearIndicator: (baseStyles, state) => ({
						...baseStyles,
						color: theme.color.grey,
						':hover': {
							opacity: 0.8,
						},
					}),
					groupHeading: (baseStyles, state) => ({
						...baseStyles,
						fontSize: theme.fontSize.s16,
						fontWeight: theme.fontWeight.medium,
						padding: '8px',
					}),
					option: (baseStyles, state) => ({
						...baseStyles,
						paddingLeft: '22px',
						backgroundColor: state.isSelected ? theme.color.primary : 'transparent',
						':active': {
							backgroundColor: theme.color.primaryLight,
						},
					}),
				}}
			/>
		</SearchWrapper>
	);
};

export default SortDropdown;
