import React, { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';

// Types
import { TContentfulSection, THeadMeta } from '../utils/types';

// Components
import SectionBuilder from '../contentful/SectionBuilder';
import ProductsList from '../components/Sections/Products/ProductsList';
import HeadMeta from 'src/components/Molecules/HeadMeta/HeadMeta';

//  Utils
import { trackAnalytic } from 'src/utils/gtag';
import { categories } from 'src/utils/data/categories';

type TProductsPage = {
	data: {
		contentfulPage: {
			sections: TContentfulSection[]
		} & THeadMeta
		bannerSection: TContentfulSection
	}
	// eslint-disable-next-line react/no-unused-prop-types
	location: {
		search: string
	}
}

const Products: React.FC<TProductsPage> = (props) => {
	const {
		data: {
			contentfulPage,
			bannerSection,
		},
		location,
	} = props;

	const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

	useEffect(() => {
		const categorySearch = urlSearchParams.get('category');
		if (categorySearch) {
			trackAnalytic('symptom_selected', {
				symptomSelected: categories.find(category => category.url === categorySearch)?.name,
			});
		}
	}, [urlSearchParams]);

	return (
		<main>
			<ProductsList />
			{SectionBuilder([bannerSection])}
		</main>
	);
};

export default Products;

export const Head: React.FC<TProductsPage> = (props) => {
	const { data: { contentfulPage } } = props;
	return <HeadMeta meta={contentfulPage.meta || {}} />;
};

export const pageQuery = graphql`
    query ProductsQuery($slug: String!) {
        contentfulPage(slug: {eq: $slug}) {
            ...PageFields
			identifier
        }
		bannerSection: contentfulSection(identifier: {eq: "\"NEW\" Banner Section (Universal)"}) {
			...SectionFields
		}
    }
`;
