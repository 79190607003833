import React, { useEffect, useMemo, useState } from 'react';
import { navigate, Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTheme, css } from 'styled-components';

// helpers
import { TProduct } from '../../../utils/types';
import { preventSameQueryLink } from '../../../utils/url-helpers';
import { trackAnalytic } from 'src/utils/gtag';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Link } from 'fatcat-ui-library/components/Atoms/Link';

import { useGetAvailableQuantity } from 'src/utils/hooks/useGetAvailableQuantity';

// Molecules
import ProductCard from '../../Molecules/Cards/ProductCardV2';
import SortDropdown from '../../Molecules/SortDropdown/SortDropdown';
import Section from 'fatcat-ui-library/components/Molecules/Section';

// Organisms

// data
import { categories } from 'src/utils/data/categories';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';

import allergy from 'src/assets/images/categories/allergy.svg';
import generalHealthAndWellness from 'src/assets/images/categories/general-health-and-wellness.svg';
import immuneSupport from 'src/assets/images/categories/immune-support.svg';
import inflammationManagement from 'src/assets/images/categories/inflammation-management.svg';
import refluxGiDigestion from 'src/assets/images/categories/reflux-gi-digestion.svg';
import sleep from 'src/assets/images/categories/sleep.svg';
import all from 'src/assets/images/categories/all.svg';

const categoriesMap = {
	allergy,
	'general-health-and-wellness': generalHealthAndWellness,
	'immune-support': immuneSupport,
	'inflammation-management': inflammationManagement,
	'reflux-gi-digestion': refluxGiDigestion,
	sleep,
};

type TCategory = {
	name: string
	url: string
	healthConcern: string
}

const ProductList: React.FC = (props) => {
	const { search, pathname } = useLocation();
	const theme = useTheme();
	const [selectedSort, setSelectedSort] = useState('ASC');
	const { getAllProductsOutOfStockLast, loading } = useGetAvailableQuantity();
	const allProducts = useMemo(() => loading ? [] : getAllProductsOutOfStockLast(), [getAllProductsOutOfStockLast, loading]);

	const urlSearchParams = new URLSearchParams(search);

	// remove url param **page** if typed directly in search bar and value is one
	if (urlSearchParams.get('page') === '1') {
		urlSearchParams.delete('page');
		const newSearch = urlSearchParams.toString();
		navigate(newSearch ? `?${newSearch}` : pathname);
	}
	// get filterd query params
	const categoryParams = urlSearchParams.get('category');
	const categoryValue: TCategory | null | undefined = categoryParams ? categories.find(category => category.url === categoryParams) : null;

	const sortedProducts = selectedSort ? [...allProducts].sort((a: TProduct, b: TProduct) => {
		switch (selectedSort) {
			case ('ASC'): {
				if (a.name.toLocaleLowerCase() < b.name.toLowerCase()) { return -1; }
				if (a.name.toLocaleLowerCase() > b.name.toLowerCase()) { return 1; }
				return 0;
			}
			case ('DESC'): {
				if (a.name.toLocaleLowerCase() > b.name.toLowerCase()) { return -1; }
				if (a.name.toLocaleLowerCase() < b.name.toLowerCase()) { return 1; }
				return 0;
			}
			case ('price-ASC'): {
				return a.variants[0].price - b.variants[0].price;
			}
			case ('price-DESC'): {
				return b.variants[0].price - a.variants[0].price;
			}
			default: {
				return 0;
			}
		}
	}) : allProducts;

	// filter products from query params
	const products = categoryParams ? sortedProducts.filter((product: TProduct) => {
		const hasCategory = product.productHealthConcerns.some(concern => concern.healthConcern.name === categoryValue?.healthConcern);
		return hasCategory;
	}) : sortedProducts;

	useEffect(() => {
		const listName = categoryParams && categories.find(category => category.url === categoryParams);
		trackAnalytic('view_item_list', {
			item_list_name: listName ? listName.name : 'All Products',
			items: products,
		});
	}, [categoryParams, products, search]);

	return (
		<Section
			paddingBottom="s40"
			scale
		>
			<Flex
				paddingY="s24"
				largeTablet={['justifyContentCenter']}
				tablet={[{ paddingY: 's32' }]}
				mobile={[{ paddingY: 's20' }]}
			>
				<Flex
					id="products-list"
					justifyContent="flex-end"
					position="relative"
					zIndex={2}
					largeTablet={[{ maxW: '100%' }]}
				>
					<SortDropdown
						selected={(e) => { setSelectedSort(e); }}
						maxW="275px"
						tablet={[{ maxW: '335px' }]}
						mobile={[{ maxW: 'unset' }]}
					/>
				</Flex>
			</Flex>
			<Flex
				gap="20px"
				largeTablet={['column', 'alignItemsCenter']}
			>
				<Flex
					maxW="240px"
					direction="column"
					gap="10px"
					largeTablet={[{ maxW: '100%', display: 'none' }]}
				>
					<Heading
						as="h2"
						fontWeight="bold"
						marginBottom="s16"
					>
						Categories
					</Heading>
					<Link
						backgroundImage={all}
						paddingLeft="s24"
						backgroundSize="16px"
						backgroundRepeat="no-repeat"
						backgroundPosition="left center"
						to="/products#products-list"
						as={GatsbyLink}
						variant="navigation"
						w="fit-content"
						fontWeight="medium"
						styled={css`
							color: ${!categoryParams ? theme.textColor.linkColor : theme.textColor.primary} !important;
						`}
					>
						All Products
					</Link>
					{categories.map(item => (
						<Link
							backgroundImage={categoriesMap[item.url]}
							paddingLeft="s24"
							backgroundSize="16px"
							backgroundRepeat="no-repeat"
							backgroundPosition="left center"
							key={item.name}
							to={`/products/?category=${item.url}#products-list`}
							onClick={preventSameQueryLink(item.url, 'category')}
							as={GatsbyLink}
							variant="navigation"
							w="fit-content"
							fontWeight="medium"
							textColor={categoryParams === item.url ? 'linkColor' : undefined}
						>
							{item.name}
						</Link>
					))}
				</Flex>
				<Flex
					direction="column"
				>
					<Flex
						wrap
						gap="20px"
						justifyContent="flex-start"
						largeTablet={['justifyContentCenter']}
						id="products-list"
					>
						{products.map((item, index) => (
							<ProductCard key={item.id} {...item} filter={categoryValue} haveAddButton inSwiper={false} />
						))}
					</Flex>
				</Flex>
			</Flex>
		</Section>
	);
};

export default ProductList;
